export const checkStatusAllOptions = [
  { label: '已报名', value: 0 },
  { label: '审核通过', value: 1 },
  { label: '审核不通过', value: 2 },
  { label: '议价中', value: 3 },
  { label: '待重新审核', value: 4 },
  { label: '已结束', value: 9 },
  /*{ label: '已排期', value: 5 },
  { label: '终审不通过', value: 6 },
  { label: '终审议价中', value: 7 },
  { label: '待终审', value: 8 },
  { label: '待排期', value: 10 },
 */
];

export const checkStatusFinalOptions = [
  { label: '二审通过', value: 1 },
  { label: '已排期', value: 5 },
  { label: '终审不通过', value: 6 },
  { label: '终审议价中', value: 7 },
  { label: '待终审', value: 8 },
  { label: '待排期', value: 10 },
];

export const checkStatusSelectionOptions = [
  { label: '审核不通过', value: 6 },
  { label: '已排期', value: 5 },
  { label: '待排期', value: 10 },
];

<template>
  <div style="display: inline-block">
    <div class="range-box">
      <span class="title">{{title}}</span>
<!--      <a-input :value="value[0]" @input="updateLeft" />-->
      <a-input
        @change="updateLeft"
        autocomplete="off"
        v-model='value[0]'/>
      <span v-if="isneedbaifenhao" class="separatefen">%</span>
      <span class="separate">-</span>
<!--      <a-input :value="value[1]" @input="updateRight" />-->
      <a-input
        @change="updateRight"
        v-model='value[1]' />
      <span v-if="isneedbaifenhao" class="separatefen">%</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    'title': {
      type: String,
      default: '',
    },
    'value':{
      type: Array
    },
    'isneedbaifenhao': {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateLeft() {
      if( isNaN( this.value[0] )){
        this.value[0]=undefined;
        return;
      }
      var str=String(this.value[0]);
      if(str.length<1){
        this.value[0]=undefined;
        return;
      }
      var arr=str.split('.');
      if(arr.length>1){
        if(arr[1].length>2){
          this.value[0]=Number(str).toFixed(2);
          this.$emit('input', [this.value[0], this.value[1]]);
        }
      }else{
        this.value[0]=Number(str);
        this.$emit('input', [this.value[0], this.value[1]]);
      }

    },
    updateRight() {
      if( isNaN( this.value[1] ) ){
        this.value[1]=undefined;
        return;
      }
      var str=String(this.value[1]);
      if(str.length<1){
        this.value[1]=undefined;
        return;
      }
      var arr=str.split('.');
      if(arr.length>1){
        if(arr[1].length>2){
          this.value[1]=Number(str).toFixed(2);
          this.$emit('input', [this.value[0],this.value[1]]);
        }
      }else{
        this.value[1]=Number(str);
        this.$emit('input', [this.value[0], this.value[1]]);
      }
     /* this.value[1]=Number(value).toFixed(2);
      this.$emit('input', [this.value[0],Number(value).toFixed(2)])*/
    }
  }
}
</script>
<style lang="less" scoped>
.range-box {
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding-left: 6px;
  height: 32px;
  box-sizing: border-box;
  overflow: hidden;
  width: 250px;

  .title {
    padding-right: 6px;
    line-height: 1.5;
    color: #bfbfbf;
  }

  .separate {
    padding: 0 6px;
    line-height: 1;
  }
  .separatefen {
    padding: 0 6px;
    margin-top: 0;
    height: 26px;
    display: inline-block;
    line-height: 26px;
  }
  input {
    -moz-appearance:textfield;
  }
  input::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
  .ant-input {
    text-align: center;
    -webkit-appearance: none;
    margin: 0;
    border-radius: 0;
    flex: 1;
    border: none !important;
    outline: none !important;
    height: 100%;
    overflow: hidden;
    transition: none;
    box-shadow: none !important;
    /deep/ .ant-input-number {
      display: none;
    }
    /deep/ .ant-input-number-handler-wrap{
      display: none;
    }
    /deep/ .ant-input-number-input-wrap {
      width: 100%;
      padding: 0px;
      .ant-input-number-input {
        padding: 0px;
        text-align: center;
      }
    }
  }
}
</style>

<template>
  <div>
    <div>
      <div class="select-form-div" ref="detailRef">
        <a-form layout="inline" :form="queryParams">
          <div class="filter-condition-form">
            <a-form-item v-if="step === 1" label="寄样单号：">
              <a-input
                v-model.trim="queryParams.sample_express_number"
                allowClear
                placeholder="请输入寄样单号"
                style="width: 220px"
                @keypress.enter.native="reload"
              />
            </a-form-item>
            <a-form-item label="序号：">
              <a-input
                v-model.trim="queryParams.id"
                allowClear
                placeholder="请输入序号"
                style="width: 220px"
                @keypress.enter.native="reload"
                @change="queryParams.id = queryParams.id.replace(/^[0]+/, '')"
              />
            </a-form-item>
            <a-form-item label="店铺名称：">
              <a-input
                v-model.trim="queryParams.shop_name"
                allowClear
                placeholder="请输入店铺名称"
                style="width: 220px"
                @keypress.enter.native="reload"
              />
            </a-form-item>
            <!--   <a-form-item label="店铺类型：">
                <a-select
                  v-model="queryParams.brand_type"
                  placeholder="店铺类型"
                  allowClear
                  style="width: 220px"
                >
                  <a-select-option
                    v-for="(item, key) in merchantTypeOptions"
                    :key="key"
                    :value="item.value"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
              </a-form-item>
              <a-form-item label="产品分类：">
                <a-input
                  v-model.trim="queryParams.merchant_category"
                  allowClear
                  placeholder="请输入产品分类"
                  style="width: 220px"
                  @keypress.enter.native="reload"
                />
              </a-form-item>-->
            <a-form-item label="商品名称：">
              <a-input
                v-model.trim="queryParams.item_name"
                allowClear
                placeholder="请输入商品名称"
                style="width: 220px"
                @keypress.enter.native="reload"
              />
            </a-form-item>
            <a-form-item label="商品ID：">
              <a-input
                v-model.trim="queryParams.item_id"
                allowClear
                placeholder="请输入商品ID"
                style="width: 220px"
                @keypress.enter.native="reload"
              />
            </a-form-item>
            <!--            <a-form-item label="公开佣金" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
              <goods-range :isneedbaifenhao="true" v-model="queryParams.item_id__commission_rate" />
            </a-form-item>-->
            <a-form-item label="达人佣金" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
              <goods-range
                :isneedbaifenhao="true"
                v-model="queryParams.exclusive_commission_rate"
              />
            </a-form-item>
            <a-form-item label="团长佣金" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
              <goods-range
                :isneedbaifenhao="true"
                v-model="queryParams.item_id__colonel_commission_rate"
              />
            </a-form-item>
            <!--
            <a-form-item label="口碑分" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
              <goods-range
                :isneedbaifenhao="false"
                v-model="queryParams.merchant_experience_score"
              />
            </a-form-item>
            <a-form-item label="体验分" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
              <goods-range :isneedbaifenhao="false" v-model="queryParams.service_score" />
            </a-form-item>
            <a-form-item label="公司名称：">
              <a-input
                v-model.trim="queryParams.supplier_name"
                allowClear
                placeholder="请输入公司名称"
                style="width: 220px"
                @keypress.enter.native="reload"
              />
            </a-form-item>-->
            <a-form-item v-if="step !== 4" label="对接招商：">
              <a-input
                v-model.trim="queryParams.contact_username"
                allowClear
                placeholder="请输入招商人员"
                style="width: 220px"
                @keypress.enter.native="reload"
              />
            </a-form-item>
            <a-form-item label="审核状态：">
              <a-select
                v-model="queryParams.check_status"
                placeholder="审核状态"
                allowClear
                style="width: 220px"
              >
                <a-select-option
                  v-for="(item, key) in checkStatusOptions"
                  :key="key"
                  :value="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item v-if="step !== 4" label="到样日期：">
              <a-date-picker
                style="width: 220px"
                type="datetime"
                v-model="queryParams.sample_date"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                placeholder="选择到样日期"
              />
            </a-form-item>
            <!--    <a-form-item label="是否退样：">
               <a-select
                 v-model="queryParams.is_recycle"
                 placeholder="全部"
                 allowClear
                 style="width: 220px"
               >
                 <a-select-option
                   v-for="(item, key) in recycleTypeOptions"
                   :key="key"
                   :value="item.value"
                   >{{ item.label }}</a-select-option
                 >
               </a-select>
             </a-form-item>
             <a-form-item label="清库：">
               <a-select
                 v-model="queryParams.is_clear"
                 placeholder="全部"
                 allowClear
                 style="width: 220px"
               >
                 <a-select-option
                   v-for="(item, key) in recycleTypeOptions"
                   :key="key"
                   :value="item.value"
                   >{{ item.label }}</a-select-option
                 >
               </a-select>
             </a-form-item>
             <a-form-item v-if="step === 3" label="排期：">
               <a-date-picker
                 style="width: 220px"
                 type="datetime"
                 v-model="queryParams.live_date"
                 format="YYYY-MM-DD"
                 valueFormat="YYYY-MM-DD"
                 placeholder="选择直播日期"
               />
             </a-form-item>
             <a-form-item v-if="step === 3" label="主播：">
               <a-input
                 v-model.trim="queryParams.live_user"
                 allowClear
                 placeholder="请输入主播"
                 style="width: 220px"
                 @keypress.enter.native="reload"
               />
             </a-form-item>-->
          </div>
        </a-form>
      </div>
      <div class="btn-lines">
        <a-button type="primary" :loading="listLoading" @click="reload">查询</a-button>
        <a-button @click="reset">重置</a-button>
        <a-button @click="closeClick">关闭</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api';
import GoodsRange from '@/components/admin/goods/goods-range';
import {
  checkStatusAllOptions,
  checkStatusFinalOptions,
  checkStatusSelectionOptions,
} from '@/pages/admin/merchant/use';
export default defineComponent({
  props: {
    queryForm: {
      type: Object,
      default: undefined,
    },
    step: {
      type: Number,
      default: 1,
    },
    listLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: { GoodsRange },
  methods: {
    // 修改佣金比例
    onChangeExternalCommission(value) {
      this.queryParams.exclusive_commission_rate = value;
    },
  },
  setup(props, ctx) {
    const queryParams = ref(props.queryForm);
    watch(
      () => props.queryForm,
      () => {
        queryParams.value = props.queryForm;
      },
      {
        deep: true,
      },
    );
    const reload = () => {
      console.log('----');
      ctx.emit('reload', true, queryParams.value);
    };
    const checkStatusOptions = [
      /* {
        label: '全部',
        value: undefined,
      },*/
      {
        label: '待审核',
        value: 0,
      },
      {
        label: '已报名',
        value: 1,
      },
      {
        label: '未通过',
        value: 2,
      },
    ];
    const reset = () => {
      ctx.emit('reset');
    };
    const closeClick = () => {
      console.log('----');
      ctx.emit('closeClick');
    };
    return {
      reset,
      closeClick,
      checkStatusOptions:
        props.step === 1
          ? checkStatusOptions
          : props.step === 2
          ? checkStatusAllOptions
          : props.step === 4
          ? checkStatusSelectionOptions
          : checkStatusFinalOptions,
      reload,
      queryParams,
      recycleTypeOptions: [
        {
          label: '是',
          value: 1,
        },
        {
          label: '否',
          value: 0,
        },
      ],
      merchantTypeOptions: [
        {
          label: '品牌方',
          value: 1,
        },
        {
          label: '代理商',
          value: 2,
        },
        {
          label: '黑标',
          value: 3,
        },
      ],
    };
  },
});
</script>

<style lang="less">
.select-div {
  .range-box {
    width: 220px !important;
    height: 28px !important;
  }
  .ant-form-item .ant-form-item-label,
  .ant-form-item .ant-form-item-label label,
  .ant-form-item .ant-form-item-control-wrapper,
  .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control,
  .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control ant-input-affix-wrapper,
  .ant-input,
  .ant-btn,
  .ant-select,
  .ant-select-selection__rendered,
  .ant-select-selection--single {
    font-size: 12px;
    height: 28px;
    line-height: 27px;
  }
  .ant-form-item .ant-form-item-label,
  .ant-form-item .ant-form-item-label label,
  .ant-form-item-control,
  .ant-input,
  .ant-select-selection__rendered {
    line-height: 26px;
    display: inline-block;
  }
  .ant-form-item {
    margin-bottom: 16px;
    margin-right: 12px;
    .ant-form-item-children {
      line-height: 26px;
      display: inline-block;
      padding: 0;
      height: 28px;
      > div {
        position: absolute;
      }
    }
  }
  .ant-drawer-body {
    padding: 18px;
  }
  .select-form-div {
    height: calc(100vh - 160px);
    overflow: auto;
    textarea.ant-input {
      margin-left: 100px;
      width: 620px;
    }
    .ant-form-item {
      width: 300px;
      margin-right: 0;
      .ant-input-affix-wrapper .ant-input:not(:last-child) {
        padding: 0 12px;
      }
      .ant-form-item-label {
        width: 80px;
      }
    }
  }
  .btn-lines {
    display: flex;
    justify-content: center;
    height: 50px;
    padding-top: 12px;
    align-items: end;
    .ant-btn {
      margin-right: 12px;
      line-height: 26px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
